import { Label } from 'reactstrap';
import { ObjectType } from '../../../../types/common';
import { isPreFix } from '../../../../helpers/stringsHelper';
import { Dispatch, SetStateAction } from 'react';
import { confirmDialogue } from '../../../../helpers/confirmationDialogue';

type Props = {
  item: ObjectType;
  index: number;
  isEdited: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  handleChange: any;
  hoveredItem: any;
  deleteFee?: any;
};

const LabelComponent = (props: Props) => {
  const {
    item,
    index,
    isEdited,
    setIsEdited,
    handleChange,
    hoveredItem,
    deleteFee,
  } = props;

  return (
    <>
      <Label for={item.name} className='d-flex '>
        <div className='d-flex'>
          {isPreFix(item?.amount_sufix) && <span>₦</span>}
          {/* <label> */}
          {isEdited ? (
            <input
              className=' border-0 in-input ps-0 ms-2 fw-bolder bg-transparent cursor-pointer in-input'
              onChange={(e) => handleChange(e, index)}
              name={item.name}
              value={item.amount}
              id={item.name}
              type='number'
              autoFocus={hoveredItem === item.name}
              readOnly={hoveredItem !== item.name}
              style={{ width: 80, height: 20 }}
            />
          ) : (
            <div className='mx-2'>{item.amount.toLocaleString()}</div>
          )}

          {!isPreFix(item?.amount_sufix) && (
            <span className='me-3'>{item.amount_sufix}</span>
          )}
        </div>

        {!isEdited && (
          <div className='ms-1' style={{ width: 10 }}>
            <div className={`d-flex ${item.name !== hoveredItem && 'd-none'}`}>
              <span
                className='cursor-pointer'
                onClick={() => setIsEdited(true)}
              >
                <img src='/svgs/edit.svg' />
              </span>
            </div>
          </div>
        )}
      </Label>
      {deleteFee && (
        <div
          className={`ms-3 ${item.name !== hoveredItem && 'd-none'}`}
          style={{ marginBottom: 10 }}
          onClick={() =>
            confirmDialogue({
              message: 'Delete Fee',
              asyncFunction: () => deleteFee(index),
            })
          }
        >
          <span>
            <img src='/svgs/delete.svg' />
          </span>
        </div>
      )}
    </>
  );
};

export default LabelComponent;
